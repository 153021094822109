import {ProcessModel, Project} from '../process/Models';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';

import {BehaviorSubject, interval, Observable, of} from 'rxjs';
import {MatTableDataSource} from "@angular/material";
import {CreateProjectService} from "../create-project/create-project.service";
import {Router} from "@angular/router";
import {ExportDataToExcelService} from "../export-data-to-excel.service";


@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit {
  processes: ProcessModel[] = null;
  currentProject: Project;
  dataSource = new MatTableDataSource(this.processes);
  displayedColumns: string[] = ['status', 'name', 'currentTime', 'count', 'average'];
  timeOld: any;
  timeNew: any;
  currentTime: any;
  processName: string;

  // called when this component is opend the first time
  constructor(private router: Router,
              public createProjectService: CreateProjectService, private exportDataToExcelService: ExportDataToExcelService, private cdr: ChangeDetectorRef) {

  }

  // called when this component is opened the first time; AFTER THE CONSTRUCTOR
  ngOnInit() {

    // clear the process array
    this.processes = [];

    // every 33 milliseconds refresh the view of the stopwatch
    interval(33).subscribe(() => {
      const t = new Date().getTime();
      this.currentTime = (t - this.timeNew);
    });

  }

  // called when we click on the Button of any process
  bFunction(processName: string, currentProcess: any) {
    this.timeNew = new Date().getTime();
    // loop over all processes
    for (const element of this.createProjectService.currentProject.processes) {
      // if element is clicked last, push the current time to this elments array; after that set lastClick to false
      if (element.lastClick === true) {
        element.lastClick = false;
        element.times.push(this.timeNew - this.timeOld);
      }
      // if process name = same element clicked last --> set lastClick true again
      if (element.name === processName) {
        element.lastClick = true;
      }
    }
    // set the timOld variable to the current Time
    this.timeOld = new Date().getTime();
  }

  // create a new Process: Create a new Object from the type "ProcessModel" and push it into the processes array;
  addProcess() {
    if (this.processName) {
      this.processes.push(new ProcessModel(this.processName));
      this.createProjectService.currentProject.processes.push(new ProcessModel(this.processName));
      this.createProjectService.deleteProjectById(this.createProjectService.currentProject.id);
      this.createProjectService.saveData(this.createProjectService.currentProject);
    this.createProjectService.getProjectsFromDb().then((e) => {
      this.createProjectService.currentProject = e.filter(menuItem => menuItem.route === this.createProjectService.currentProject.route)[0];
    });
      // afterthat delete the current process name in our HTML input field
      this.processName = '';
      setTimeout(() => {
        this.cdr.detectChanges();
      }, 1000);

    }
  }

  resetTime() {
    this.timeNew = new Date().getTime();
    // loop over all processes
    for (const element of this.createProjectService.currentProject.processes) {
      // if element is clicked last, push the current time to this elments array; afterthat set lastClick to false
      if (element.lastClick === true) {
        element.lastClick = false;
        element.times.push(this.timeNew - this.timeOld);
      }
    }
    this.createProjectService.saveProcessInDb();
  }

  averageTime(times: any[]) {
    let allTimes = 0;
    times.forEach((time) => {
      allTimes = allTimes + time;
    })
    return allTimes / times.length;
  }

  exportDataToExcel() {
    this.exportDataToExcelService.convertToExcel();
  }
}


//TODO: updates process (ohne neuladen),
//Routing auf project ID
