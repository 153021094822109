import { ProcessModel } from './Models';
import { Component, OnInit, Input, HostListener, Directive, ElementRef } from '@angular/core';

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.css'],


})
export class ProcessComponent implements OnInit {

  @Input()
  name: string;

  @Input()
  times: any[];

  constructor() {
  }


  ngOnInit() {

  }

}
