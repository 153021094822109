//this is our Datatype; this means, when we create an Object of this class, the object has always 3 attributes: name, times and lastClick
export class ProcessModel {
    // the name of the Process; appears on the button and on the label in the process HTML
    name: string;
    // an array of times, that are tracked for this process
    times: number[];
    // if you last clicked the element, this boolean is true for that element. Now we know the time we are tracking at the Moment belongs to that process
    lastClick: boolean;

    // this is called, when we create an Object of the Class "ProcessModel" (for example when we do new ProcessModel("testName"))
    // if we create such an object we need to give over a name as a parameter
    constructor(name: string) {
        // the name we get from the creation of the object, and save it as the name of the process.
        this.name = name;
        // the array of times is empty, because when we create the process, the process doesn't have any times tracked so far
        this.times = [];
        // lastClick is false by default, bevause when we create the process, we never clicked his button before
        this.lastClick = false;
    }
}

export class Project {
  id: number;
  name: string;
  date?: any;
  product?: string;
  workstation?: string;
  line?: string;
  factory?: string;
  route?: string;
  processes: ProcessModel[];


  constructor() {
    this.processes = [];
  }
}
