
import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {timestamp} from "rxjs/internal/operators";
import {Project} from "../process/Models";
import {CreateProjectService} from "./create-project.service";

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.css']
})
export class CreateProjectComponent implements OnInit {
  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private createProjectService: CreateProjectService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      Name: ['', Validators.required],
      Date: ({value: '', disabled: true}),
      Factory: '',
      line:'',
      workstation:'',
      product:''
    });

    this.form.valueChanges.subscribe((e) => console.log(e));
  }

  createProject(){
    const project: Project = {
      name:'',
      date:'',
        factory:'',
      id:0,
      route:'',
      processes: [],
      line: '',
      workstation: '',
      product: '',
    };
    project.name = this.form.value.Name;
    project.date = new Date();
    project.factory = this.form.value.Factory;
    project.id = this.createId();
    project.line = this.form.value.line;
    project.workstation = this.form.value.workstation;
    project.product = this.form.value.product;
    console.log(project);
    this.createProjectService.addProject(project);
  }
  createId(): number{
    const date = new Date();
     return date.getTime();
  }

}
