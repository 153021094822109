import {Injectable} from '@angular/core';
import {ProcessModel, Project} from "../process/Models";
import {NavigationStart, Router, Event} from "@angular/router";
import {ProjectDB} from "./projectDB";

@Injectable({
  providedIn: 'root'
})
export class CreateProjectService {

  projectDB: ProjectDB;
  project: Project;
  currentProject: Project;
  // This is the menu of the projects in the right
  fillerNav = [
    // {'name': 'Create project', 'route': '/create-project'},
  ];
  currentProjectRoute = '';

  constructor(public router: Router, ) {
    this.projectDB = new ProjectDB();
    router.events.subscribe((urlEvent: Event) => {
      this.getProjectsFromDb().then((e) => {
        this.fillerNav = e;
        if (urlEvent instanceof NavigationStart) {
          this.currentProject = this.fillerNav.filter(menuItem => menuItem.route === urlEvent.url)[0];
          if (this.currentProject) {
          this.currentProjectRoute = this.currentProject.route;
          }
        }
      });
    });
  }

  addProject(project: Project) {
    project.route = '/projects/' + project.id;
    project.processes = [];
    this.fillerNav.push(project);
    this.projectDB.projects.put(project);
    this.router.navigateByUrl(project.route);
  }

  async getProjectsFromDb(): Promise<any> {
    return this.projectDB.projects.toArray();
  }

  async deleteProjectById(id: number): Promise<any> {
    await this.projectDB.projects.where('id').equals(id).delete();
  }

  async saveData(project: Project) {
    await this.projectDB.projects.put(project);
  }

  saveProcessInDb() {
    this.deleteProjectById(this.currentProject.id);
    this.saveData(this.currentProject);
  }
}
