import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {CreateProjectService} from "./create-project/create-project.service";
import {timestamp} from "rxjs/internal/operators";

@Injectable({
  providedIn: 'root'
})
export class ExportDataToExcelService {

  constructor(private createProjectService: CreateProjectService) { }

  convertToExcel() {
    let wb = XLSX.utils.book_new();
    wb.Props = {
      Title: "SheetJS Tutorial",
      Subject: "Test",
      Author: "Red Stapler",
      CreatedDate: new Date(2017,12,19)
    };
    wb.SheetNames.push("Test Sheet");
    var ws_data = [['hello' , 'world']];  //a row with 2 columns
    let row = [];
    let timeCount = 0;
    this.createProjectService.currentProject.processes.forEach((process, index) => {
      let averageTime = 0;
      let sumTime = 0;
      process.times.forEach((time) => {
        sumTime = sumTime + time;
      if (this.createProjectService.currentProject.processes[index].times.length !== 0) {
      averageTime = sumTime/this.createProjectService.currentProject.processes[index].times.length;
      } else {
        averageTime = 0;
      }
      });
      row[index] = [process.name, process.times.length, averageTime, '', '', '', ...process.times];
        if (process.times.length > timeCount) {
        timeCount = process.times.length+1;
      }
    });
    let timeStrings = [];
    for (let index = 1;  index < timeCount; index++) {
      timeStrings.push('Time ' + index);
    };
    if (!this.createProjectService.currentProject.date) {
      this.createProjectService.currentProject.date = '';
    }
    var ws_data: string[][] = [
      [this.createProjectService.currentProject.name],
        [this.createProjectService.currentProject.line],
        [this.createProjectService.currentProject.workstation],
        [this.createProjectService.currentProject.date.toString()],
        [this.createProjectService.currentProject.product],
      ['Process name', 'Count', 'Average', 'Optimization',	'Comment',	'Potential optimization', ...timeStrings],
      ...row,
      ['Total']
      // [this.createProjectService.currentProject.processes[0].name,this.createProjectService.currentProject.processes[0].times.length, averageTime]
    ];
    let ws = XLSX.utils.aoa_to_sheet(ws_data);
    // let ws = XLSX.utils.sheet_to_json({'test Object': 'hola'})
    wb.Sheets["Test Sheet"] = ws;

    var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});

    saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), 'test.xlsx');

}
   s2ab(s) {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
  }
}
