import Dexie from "dexie";
import {Project} from "../process/Models";

export class ProjectDB extends Dexie {
  projects: Dexie.Table<Project, string>;
  constructor() {
    super("Project");
    this.version(1).stores({
      projects: 'id'
    });
  }
}

