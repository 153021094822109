import {CreateProjectComponent} from "./create-project/create-project.component";
import {Routes} from "@angular/router";
import {TimerComponent} from "./timer/timer.component";
import {ProcessMenuComponent} from "./process-menu/process-menu.component";

export const routes: Routes = [
  {
    path: 'create-project',
    component: CreateProjectComponent,
  },
  {
    path: 'projects',
    component: ProcessMenuComponent,
  },
  {
    path: 'projects/:id',
    component: ProcessMenuComponent,
  }
];
